import { Container, Row, Col } from 'react-bootstrap';
import  "./Footer.css";
import config from "../data/config.json";
import Logo from './Logo';
import { Link } from 'react-router-dom';

type Props = {}

const Footer = (props: Props) => {
  return (
    <div className="footer">
      <Container >
        <Row style={{marginTop:"50px"}}>
            <Col className="col-12 col-lg-3 info-section">
                <Logo width="100" style={{marginBottom:"15px"}} theme="light"/>
                <div className="info">
                  {config.info.COMPANY}
                </div>
                <div className="info">
                  {config.info.PHONE}
                </div>
                <div className="info">
                  {config.info.EMAIL}
                </div>
            </Col>
            <Col className="col-0 col-lg-9 d-none d-lg-block menu">
              <Row>
                {
                  config.menus.map((menu) => {
                    return (
                      <Col>
                        <div className="title"> 
                            { menu.sub.length == 0 ? (
                                <Link to={menu.url}>{menu.name}</Link>
                            ) : menu.name
                            }
                        </div>
                      </Col>
                    )
                  })
                }
              </Row>
            </Col>
        </Row>
      </Container>
        <hr/>
        <div className="" style={{textAlign:"center",width:"100%",padding:"20px 0 0 0"}}>
                <Link to="/policy">Privacy Policy</Link>   |   <Link to="/terms"> Condition of Use </Link> <br/><br/>
                Copyrights(C) 2023. All Rights Reserved
        </div>
    </div>
  )
}

export default Footer;