import React,{ useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import config from "../data/config.json";
import "./HoverMenu.css";

const RANGE = 500;
const maxScale = 1.15;

export default function HoverMenu() {

    const [x, setX] = useState(1)

    interface Menu{
        name:string
        url:string,
    }

    const hover =(e: React.MouseEvent<HTMLDivElement>) => {
        //const curve = createCurve(range, e.clientX,1, maxScale)
        if (Math.abs(x-e.clientX) > 45) {
            setX(e.clientX);
        }
    }
    
    const leave = (e: React.MouseEvent<HTMLDivElement>) => {
            setX(1);
    }

    const curve = (currentX : number)=>{
        const startX = x - RANGE/2;
        const endX = x + RANGE/2;
        if (currentX < startX || currentX > endX) return 1;
        const x_rate = (currentX - startX)/RANGE;
        const y_dif = maxScale-1;
        return Math.sin(x_rate * Math.PI) * y_dif + 1;

        const half_range = RANGE/2;
        const graph_x = x - startX;
        return (-(1/half_range)^2 * (graph_x - half_range) ^2 +1) * y_dif +1;
    }

    useEffect(() => {
        const items = document.querySelectorAll('.menu-item');
        for (var i = 0, len = items.length; i < len; i++) {
            const item = items[i] as HTMLElement;
            //console.log(item);
            const rect = item.getBoundingClientRect();
            const x = rect.x + rect.width/2;
            const scale = curve(x)
            // console.log(scale);
            
            item.style.setProperty('--factor', scale.toString());
        }
    }, [x]);

    const renderMenu = (menu:Menu, index:number) =>{
        return [
            
                <Link className="title menu-item" to={menu.url} key={index}>{menu.name} </Link>,
                <div className="space"></div>
        ]
    }

    return (
        <div className="header" onMouseMove={hover} onMouseLeave={leave}>
            {
                config.menus.map((ele , index)=> {
                    return renderMenu(ele,index)
                })
            }
        </div>
    )
}
