import React, { useLayoutEffect, useRef } from 'react';
import "./Home.css";
import DigitRain from '../components/DigitRain';
import Portfolio from '../components/Portfolio';
import GlowRing from '../components/GlowRing';
import gsap from "gsap";
import { Fade } from "react-awesome-reveal";
import config from '../data/config.json'

function Home() {

    const boxRef = useRef<SVGFETurbulenceElement>(null);

    useLayoutEffect(() => {

        const tl = gsap.timeline({
            paused:true,
            onUpdate(){
                boxRef.current?.setAttribute("baseFrequency",`0 ${val.freq}`);
            }
        });
        const val = {
            freq: 0.0001,
        }
        tl.to(val, {
            freq: 0.4,
            duration:0.2
        });
        tl.to(val, {
            freq: 0.0001,
            duration: 0.2
        });

        tl.restart();

        setInterval(() => {
            tl.restart();
        }, 7000);
      });
      
    return (
            <div id="home">
                <div className="slogan-wrapper">
                    <div className="wrapper">
                    <GlowRing />
                    <div className="slogan"  >
                    {config.app_name}
                    </div>
                    </div>
                </div>

                <svg style={{display:"none"}}>
                    <defs>
                    <filter id="noise" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="linearRGB">
                        <feTurbulence ref={boxRef} type="turbulence" baseFrequency="0 0.4" numOctaves="2" seed="2" stitchTiles="stitch" x="0%" y="0%" width="100%" height="100%" result="turbulence"/>
                        <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="50" xChannelSelector="R" yChannelSelector="B" x="0%" y="0%" width="100%" height="100%" result="displacementMap"/>
                    </filter>
                    </defs>
                </svg>

                <DigitRain />
                {/* <VideoBgContainer /> */}
                <h2 className="title" >相关案例</h2>
                <Fade duration={1000} delay={500} direction="down" style={{zIndex:"100",position:"relative"}}>
                    
                    <Portfolio />
                </Fade>
                
            </div>
    );
}

export default Home;
