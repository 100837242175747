import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { faS, faGlobe, faToriiGate, faMobileScreen, faSatelliteDish, faBuildingUser, faFileLines, faCode, faVialVirus, faNetworkWired, faEye } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


library.add(faS, faGlobe, faToriiGate, faMobileScreen,faSatelliteDish,faBuildingUser, faFileLines,faCode,faVialVirus,faNetworkWired,faEye);

root.render(
    <React.StrictMode>
        <div className="App">
            <BrowserRouter >
                <ScrollToTop/>
                <Navigation />
                <App />
                <Footer />
            </BrowserRouter>
        </div>
    </React.StrictMode>
)
