import React from 'react'
import config from "../data/config.json";
import LinkButton from "../components/LinkButton";
import "./NotFound.css";

export default function NotFound() {
  return (
    <div className="notFound">
         <img src={`${config.s3_image_url}/${config.notfound.img}`} alt=""/>
         <div className="text"> Looks like you are lost in Lifeline</div>

         <LinkButton text="Back to Homepage" theme="dark" style={{marginTop:"20px",fontSize:"16px"}} to="/" />
    </div>
  )
}
