import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import TitleSection from '../../components/TitleSection'
import config from '../../data/config.json'
import { Fade } from "react-awesome-reveal";
import "./Procedure.css";

export const Procedure = () => {
    return (
        <div id="procedure">
            <div className="blank"></div>
            <Fade duration={1000} delay={500} >
            <TitleSection title={'合作流程'} url={''} />
            <div className="steps">
                <div className="container">
                    {
                        config.procedure.steps.map((step, index) => { 
                            return (
                                <Row>
                                    <Col className="col-12 col-lg-4 left">
                                        {/* <img src={`${config.s3_image_url}/claimProcess/${step.img}`} alt="" /> */}
                                        <div className="icon-wrapper">
                                            <FontAwesomeIcon icon={step.icon as [IconPrefix, IconName]} className="icon"/>
                                        </div>
                                        <h3>{`步骤 ${index+1}`}</h3>
                                    </Col>
                                    <Col className="col-12 col-lg-8 step-detail">
                                        {/* <h2>{`步骤 ${index+1}`}</h2> */}
                                        <h2>{step.title}</h2>
                                        <div className="text">
                                            {step.description.map((description) => { 
                                                return <div>{description}</div>
                                            })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            )
                            })
                    }
                </div>
            </div>
            </Fade>
        </div>
    )
}
