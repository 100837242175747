import React from 'react'
import "./TitleSection.css";

type TitleSectionProp ={
    title : string,
    url: string
    height?:number
};

export default function TitleSection(props:TitleSectionProp) {
  let style:React.CSSProperties | undefined = {
    backgroundImage:`url(${props.url})`,
    backgroundColor:"black",
  };

  if (props.height) {
    //style['height'] = `${props.height}px`;
    //style['lineHeight'] = `${props.height}px`;
  }

  return (
    <div  className="titleSection" style={style} >
        {props.title.split('').map((char,index) => (<span style={{animationDelay:`${index*0.2}s`}}>{char}</span>))}
    </div>
  )
}
