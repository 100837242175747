import React from 'react'
import TitleSection from '../../components/TitleSection'
import './About.css'
import config from "../../data/config.json";
import { Fade } from "react-awesome-reveal";

export default function About() {
    return (
        <div id="aboutUs">
            <div className="blank"></div>
            <Fade duration={1000} delay={500} direction="up">
                <TitleSection title={'关于我们'} url={''} />
                <div className="container">
                    <div className="text">
                        {config.aboutUs.text[0]}
                    </div>
                </div>
            </Fade>
        </div>
    )
}
