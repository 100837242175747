import React, { useEffect, } from 'react';
import './App.css'
import Home from "./page/Home";
import ContactUs from "./page/ContactUs";
import { Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import Login from './page/Login';
import useScript from "./components/SalesIQ";
import NotFound from './page/NotFound';
import ReactGA from 'react-ga4';
import config from "./data/config.json";
import About from './page/About/About';
import Services from './page/Services/Services';
import { Procedure } from './page/Procedure/Procedure';


function App() {
    const location = useLocation();
    
    useEffect(() => {
        document.title = config.app_title;
    });

    return (
       
            
            <Routes>
                
                <Route path="/" element={<Home/>} />
                <Route path="/contact" element={<ContactUs/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/services" element={<Services/>} />
                <Route path="/procedure" element={<Procedure/>} />
                {
                /* <Route path="/login" element={<Login/>} /> 
                <Route path="/register" element={<Register/>} />
                <Route path="/forget" element={<ForgetPassword/>} /> */
                }
                <Route path="/*" element={<NotFound/>} />
                
            </Routes>
    );
}

export default App;
