import React from 'react'
import "./Protfolio.css";
import heatlist from '../images/heatlist.png'
import cmg from '../images/CMG.png'
import ctc from '../images/ctc.jpg'
import GLL from '../images/GLL.png'
import Microsoft from '../images/Microsoft.png'
import Gr from '../images/GR.png'
import orico from '../images/orico.webp'

export default function Portfolio() {
  return (
    <div className="portfolio">
        <div className="wrapper-outter">
            <div className="wrap">
                <div className="line">
                    <div className="item"></div>
                    <div className="item"></div>
                    <div className="item"></div>
                    <div className="item"></div>
                </div>
                <div className="line">
                    <div className="item">
                        
                    </div>
                    <div className="item">
                        <div className="logo">
                            <img src={heatlist}/>
                        </div>
                        <div className="title">Heatlist</div>
                    </div>
                    <div className="item">
                    <div className="logo">
                            <img src={cmg} height="100px"/>
                            <div className="title">Heatlist</div>
                        </div>
                    </div>
                    <div className="item">
                    <div className="logo">
                            <img src={Microsoft} height="100px"/>
                            <div className="title">Heatlist</div>
                        </div>
                    </div>
                    <div className="item"></div>
                </div>
                <div className="line">
                    <div className="item">
                    <div className="logo">
                            <img src={Gr} height="100px"/>
                            <div className="title">Heatlist</div>
                        </div>
                    </div>
                    <div className="item">
                    <div className="logo">
                            <img src={GLL} height="100px"/>
                            <div className="title">Heatlist</div>
                        </div>
                    </div>
                    <div className="item">
                    <div className="logo">
                            <img src={ctc} height="100px"/>
                            <div className="title">Heatlist</div>
                        </div>
                    </div>
                    <div className="item">
                    <div className="logo">
                            <img src={orico} height="100px"/>
                            <div className="title">Heatlist</div>
                        </div>
                    </div>
                </div>
                <div className="line">
                    <div className="item"></div>
                    <div className="item"></div>
                    <div className="item"></div>
                    <div className="item"></div>
                    <div className="item"></div>
                </div>
            </div>
        </div>
        <div className="mobile-wrapper">
            <div className="line">
                <div className="item">
                    <div className="title">Heatlist/热帖房屋</div>
                    <div className="logo" ><img src={heatlist} alt="" /></div>
                </div>
                <div className="item">
                    <div className="title">Create Music Group</div>
                    <div className="logo"><img src={cmg} alt="" /></div>
                </div>
            </div>
            <div className="line">
                <div className="item">
                    <div className="title">Microsoft Security/微软安全</div>
                    <div className="logo"><img src={Microsoft} alt="" /></div>
                </div>
                <div className="item">
                    <div className="title">Green Line Pos</div>
                    <div className="logo"><img src={Gr} alt="" /></div>
                </div>
            </div>
            <div className="line">
                <div className="item">
                    <div className="title">LifeLine Extended Auto Services</div>
                    <div className="logo"><img src={GLL} alt="" /></div>
                </div>
                <div className="item">
                    <div className="title">CTC Logistics Group/中贸物流</div>
                    <div className="logo"><img src={ctc} alt="" /></div>
                </div>
            </div>
            <div className="line">
                <div className="item">
                    <div className="title">Orico Pet Supply(移动端)</div>
                    <div className="logo"><img src={orico} alt="" /></div>
                </div>
                <div className="item">
                    <div className="title">Under Construction.../建设中</div>
                    <div className="logo"></div>
                </div>
            </div>
        </div>
    </div>
  )
}
