import ContactCard from "../components/ContactCard";
import { Container, Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import config from '../data/config.json'
import TitleSection from '../components/TitleSection';
import "./ContactUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { Fade } from "react-awesome-reveal";

export default function ContactUs() {

  return (
    <div id="contactUs">
        <div className="blank"></div>
        <Fade duration={1000} delay={500} direction="up">
        <TitleSection title="联系我们" url={''}/>
        <Container>
            <Row>
                {config.contactUs.sections.map((section) => (
                    <Col className="col-12 col-md-6 col-lg-6">
                        <div className="card">
                            <div className="icon">
                                <FontAwesomeIcon icon={section.icon as [IconPrefix, IconName]} />
                            </div>
                            <div className="text-wrapper">
                                <h4>
                                    {section.key}
                                </h4>
                                <div>
                                    {section.value}
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
        </Fade>
    </div>
   
  )
}
