import React from 'react'
import './GlowRing.css'

export default function GlowRing() {
  return (
    <div className="outer-ring">
        <div className="inner-ring">
            logo
        </div>
    </div>
  )
}
