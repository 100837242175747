import React from 'react'
import { Link } from 'react-router-dom';
import "./LinkButton.css";

type LinkButtonProp ={
    text:string
    theme:string
    style?: React.CSSProperties;
    to:string
}

export default function LinkButton(props:LinkButtonProp) {
  return (
    <div>
      {
        props.to.includes('http')? 
        (<a className={`LinkButton ${props.theme}`}  style={props.style} href={props.to}>
          {props.text}
        </a>)
      :
        (<Link className={`LinkButton ${props.theme}`} style={props.style} to={props.to}>
            {props.text}
        </Link>)
      }
    </div>
     
    
    
  )
}
