import React,{ useEffect, useState } from 'react'
import  "./DigitRain.css";

const fontColor = "#fff";
export default function DigitRain() {

    const [flag , setflag] = useState(false);
    function isCanvas(obj: HTMLCanvasElement | HTMLElement): obj is HTMLCanvasElement {
        return obj.tagName === 'CANVAS';
    }

    const randomDigit = () => {
        const digits = [0,1,2,3,4,5,6,7,8,9];
        return digits[Math.floor(Math.random() * digits.length)].toString(); 
    }


    const randomColor = ()=>{
        const whites = Array(21).fill('#fff')
        const colors = ["#23a6d5","#f70d1a",'#8900ff','#fe6a50',...whites];
        return colors[Math.floor(Math.random() * colors.length)];
    }

    useEffect(() => {
        if (flag) {
            return;
        }
        setflag(true);
        const cvs = document.getElementById('digit-rain')
        if (cvs !== null && isCanvas(cvs)) {
            const width = window.innerWidth;
            const height = window.innerHeight;
            console.log(window.innerWidth);
            console.log(devicePixelRatio);
            let fontSize = 30;
            if (window.innerWidth < 1199) {
                fontSize = 25;
            }
            if (window.innerWidth < 767) {
                fontSize = 20;
            }
            
            cvs.width = width;
            cvs.height = height;

            const ctx = cvs.getContext('2d');

            const columnWidth = fontSize;

            const columnCount = Math.floor(width/ columnWidth);
            
            const nextChar = new Array(columnCount).fill(0);

            const draw = () => {
                if (ctx !== null) {
                    ctx.fillStyle = 'rgba(2,2,2,0.1)';
                    ctx.fillRect(0,0,width,height);
                    //ctx.clearRect(0, 0, width, height);
                    for (let i = 0; i < columnCount; i++) {
                        ctx.fillStyle = randomColor();
                        const char = randomDigit();
                        ctx.font = `${fontSize}px Arial`
                        const s = nextChar[i];
                        const x = i * columnWidth;
                        const y = (s+1) * fontSize;
                        ctx.fillText(char,x,y);
                        if (y > height && Math.random() > 0.99) {
                            nextChar[i] =0; 
                        } else{
                            nextChar[i]++;
                        }
                        
                    }
                }
            }

            draw();
            setInterval(draw, 60);
        }
        
    }, []);
    
  return (
    <canvas id="digit-rain" className="digit-rain">

    </canvas>
  )
}
