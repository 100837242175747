import React, {useState} from 'react';
import './Navigation.css'
import { Container, Navbar, NavDropdown , Nav, Row,Col, Button} from 'react-bootstrap';
import Logo from './Logo';
import {Link} from 'react-router-dom';
import config from "../data/config.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import HoverMenu from './HoverMenu';

interface SubMenu{
    name:string
    url:string, 
}

interface Menu{
    name:string
    url:string,
    sub:SubMenu[]
}

type Props = {

}

type State = {
    toggle: string,
}

class Navigation extends React.Component<Props,State> {

    constructor(props:Props){
        super(props);
        this.state = {
            toggle: "",
        }
        this.openSideBar = this.openSideBar.bind(this);
        this.closeSideBar = this.closeSideBar.bind(this);
    }


    renderMenu(menu:Menu, index:number){
        return [
            
                <Link className="title menu-item" to={menu.url} key={index}>{menu.name} </Link>,
                <div className="space"></div>
        ]
    }

    renderSidebar(menu:Menu, index:number){
        return (
            <div className="section" style={{animationDelay:`${index*0.3}s`}}>
                {
                    menu.url.length > 0 ?
                    <Link to={menu.url} className="link title" onClick={this.closeSideBar}>{menu.name}</Link> :
                    <a className="link title" href="#" key={index}>{menu.name} <FontAwesomeIcon className="" icon={faChevronDown}/></a>
                }
                {menu.sub.map((sub) => {
                    return (<div>
                        <Link to={sub.url} className="link sub" onClick={this.closeSideBar}> {sub.name} </Link> 
                    </div>)
                })}
            </div>
        );
    }

    openSideBar(){
        this.setState({
            toggle: "toggle"
        })
    }

    closeSideBar(){
        this.setState({
            toggle: ""
        })
    }

    render() {
        const style = {
            backgroundColor: '#333',
            padding:"5px 0"
        }
        // console.log(2);
        
        return  <div id="menu">
            <div >
                <div className="Menu d-none d-lg-block"  >
                    <div className="container-fluid full-screen-menu" style={{display:"flex"}}>
                        {/* <Logo width="80" style={{margin:"35px 0"}} theme={"light"}/> */}
                        <div className="title">
                            {config.app_name}
                        </div>
                        <HoverMenu />
                        {/* <div className="start-claim-btn" style={{margin:"50px 25px 25px auto"}}><Link to="/login">LOG IN</Link></div> */}
                    </div>
                    
                </div>
                <Navbar collapseOnSelect expand="lg"  variant="dark" style={style} className="d-flex d-lg-none">
                    <Navbar.Brand href="#home">
                        <Logo width="50" theme="light"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{marginRight:"10px"}} onClick={this.openSideBar}/>
                </Navbar>
            </div>
            <Container fluid className={`sidebar-wrapper ${this.state.toggle} position-absolute`}>
                <Row className="col-sm-12 col-md-7 sidebar-bg">
                    <Col className="sidebar" id="sidebar">
                        <div>
                            <Logo width="70" theme={"light"}/>
                            <Button className="close" onClick={this.closeSideBar}>X</Button>
                        </div>
                        {
                            config.menus.map((ele , index)=> {
                                return this.renderSidebar(ele,index)
                            })
                        }
                    </Col>
                </Row>
            </Container>
        </div>;
    }
  }
export default Navigation;