import React from 'react'
import TitleSection from '../../components/TitleSection'
import { Container, Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import config from '../../data/config.json'
import "./Services.css";
import { Fade } from "react-awesome-reveal";

export default function Services() {
    return (
        <div id="services">
            <div className="blank"></div>
            <Fade duration={1000} delay={500} direction="down">
            <TitleSection title={'核心服务'} url={''} />
            <Container>
                {/* <Row>
                    {config.services.sections.map((section) => (
                        <Col className="col-12 col-md-6 col-lg-6">
                            <div className="card">
                                <div className="icon">
                                    <FontAwesomeIcon icon={section.icon as [IconPrefix, IconName]} />
                                </div>
                                <h4>
                                    {section.title}
                                </h4>
                                <ul>
                                    {section.texts.map((text) => 
                                        <li> {text}</li>
                                    )}
                                </ul>
                            </div>
                        </Col>
                    ))}
                </Row> */}

                {config.services.sections.map((section) => (
                    <div className="wrapper">
                        <div className="image element">
                            <img src={`${config.s3_image_url}/${section.img}`} />
                        </div>
                        <div className="text-section element">
                            <div className="text-wrapper">
                                <div className="eng">
                                    {section.eng}
                                </div>
                                <div className="cn">
                                    {section.title}
                                </div>
                                <div className="desc">
                                    {section.texts[0]}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Container>
            </Fade>
        </div>
    )
}
