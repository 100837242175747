import logo from '../images/logo.png'
import light from '../images/logo-light.png'
import {Link} from 'react-router-dom';


type LogoProp ={
    width : string
    style?: object
    theme?: string
    className?:string
};

function Logo(props: LogoProp) {

    return (
        <Link to="/" className="logo">
            <img src={ props.theme === 'light' ? light : logo } alt="logo"  width={props.width} style={props.style} className={props.className}/>
        </Link>
       
    );
  }
export default Logo;